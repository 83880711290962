class HTTP {
    static async _fetch(url, method, body, timeout = 400) {
        let params = {
            method: method,
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: timeout
        };

        if (body) {
            params.body = JSON.stringify(body);
        }

        return fetch(url, params)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(new Error(response.statusText));
                }
            })
            .then(response => {
                const  contentType = response.headers.get("content-type");

                if(!(contentType && contentType.includes("text/json"))) {
                    throw new TypeError("Oops, we haven't got JSON!");
                }

                const json = response.json();

                if (!json.error) {
                    return json;
                } else {
                    return Promise.reject(new Error(json.error.message));
                }
            });
    };

    static async get(url, body = null) {
        return this._fetch(url, "GET", body);
    }

    static async post(url, body) {
        return this._fetch(url, "POST", body);
    }

    static param (a) {
        var s = [];
        var add = function (k, v) {
            v = typeof v === 'function' ? v() : v;
            v = v === null ? '' : v === undefined ? '' : v;
            s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
        };
        var buildParams = function (prefix, obj) {
            var i, len, key;

            if (prefix) {
                if (Array.isArray(obj)) {
                    for (i = 0, len = obj.length; i < len; i++) {
                        buildParams(
                            prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
                            obj[i]
                        );
                    }
                } else if (String(obj) === '[object Object]') {
                    for (key in obj) {
                        buildParams(prefix + '[' + key + ']', obj[key]);
                    }
                } else {
                    add(prefix, obj);
                }
            } else if (Array.isArray(obj)) {
                for (i = 0, len = obj.length; i < len; i++) {
                    add(obj[i].name, obj[i].value);
                }
            } else {
                for (key in obj) {
                    buildParams(key, obj[key]);
                }
            }
            return s;
        };

        return buildParams('', a).join('&');
    };
}

export default HTTP;
