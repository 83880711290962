import Api from './api';
import Event from "./event";

class Factory extends Event {

    static method(methodName) {
        return new Api(methodName);
    }
}

export default Factory;
