import HTTP from './http';
import Factory from './factory';

class Api {

    constructor(methodName) {
        this._uri = `/my/s3/xapi/public/?method=${methodName}`;
        this._eventName = this._getEventName(methodName);
    }

    async send(params) {
        return this._fetch(params, 'POST');
    }

    async get(params) {
        return this._fetch(params);
    }

    async _fetch(params, method = 'GET') {
        let result;

        Factory.trigger(`before${this._eventName}`);

        switch (method) {
            case 'POST':
                result = await HTTP.post(this._uri, params);
                break;
            case 'GET':
                result = await HTTP.get(`${this._uri}&${HTTP.param({param: params})}`);
                break;
            default:
                throw new Error(`Method name ${method} is not defined. Using: POST, GET`)
        }
        Factory.trigger(`after${this._eventName}`, result);

        return result;
    }

    _getEventName(methodName) {
        let classAction = [];

        for (let item of methodName.split('/')) {
            classAction.push(item.charAt(0).toUpperCase() + item.substr(1));
        }

        return classAction.join('');
    }
}

export default Api;
