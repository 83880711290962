class Event {
    static on(eventName, handler) {
        if (!Event._eventHandlers) Event._eventHandlers = {};
        if (!Event._eventHandlers[eventName]) {
            Event._eventHandlers[eventName] = [];
        }
        Event._eventHandlers[eventName].push(handler);
    }

    static off(eventName, handler) {
        let handlers = Event._eventHandlers && Event._eventHandlers[eventName];
        if (!handlers) return;
        for (let i = 0; i < handlers.length; i++) {
            if (handlers[i] === handler) {
                handlers.splice(i--, 1);
            }
        }
    }

    static trigger(eventName /*, ... */) {
        if (!Event._eventHandlers || !Event._eventHandlers[eventName]) {
            return;
        }

        let handlers = Event._eventHandlers[eventName];
        for (let i = 0; i < handlers.length; i++) {
            handlers[i].apply(Event, [].slice.call(arguments, 1));
        }
    }
}

export default Event;
